<!-- 中国国内物流跟踪列表 -->
<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" @click="$fun.routerToPage('/user/index')" />
    </template>
    <template #default>
      <div class="search">
        <inputBoxVue
          v-model="searchkeyword"
          :keydownEnter="search"
          :placeholder="$fanyi('可根据订单号搜索')"
        />
      </div>
    </template>
    <!--中国国内物流列表 -->
  </headBackBar>
  <div class="content">
    <van-tabs
      :ellipsis="false"
      v-model:active="active"
      @click-tab="clicktabs"
      title-inactive-color="#000000"
      line-width="30px"
      animated
      title-active-color="#B4272B"
    >
      <van-tab :title="$fanyi('全部')">
        <van-pull-refresh
          v-model="wuliulist.allrefreshing"
          @refresh="onRefresh"
          head-height="100"
          :loosing-text="$fanyi('释放即可刷新...')"
          :pulling-text="$fanyi('下拉刷新')"
          :loading-text="$fanyi('加载中')"
          :success-text="$fanyi('加载成功')"
        >
          <van-list
            v-model:loading="loading"
            :loading-text="$fanyi('加载中')"
            :finished="wuliulist.allfinished"
            :finished-text="$fanyi('')"
            @load="onLoad"
          >
            <div class="main">
              <div
                class="orderitem"
                v-for="(item, ind) in wuliulist.alllist"
                :key="ind"
              >
                <div
                  class="ordersn"
                  v-longpress="
                    () => {
                      copySn(item.order_sn);
                    }
                  "
                >
                  {{ $fanyi("订单号") }} : <span> {{ item.order_sn }}</span>
                </div>
                <div
                  class="item"
                  v-for="(i, index) in item.order_detail"
                  :key="index"
                >
                  <!-- !标题 -->
                  <div class="top-title">
                    <div class="left">
                      <img
                        src="../../../../assets/user-img/矢量智能对象.png"
                        alt=""
                      />
                      <span class="fanhao">{{ $fanyi("番号") }} :</span>
                      <span>#{{ i.sorting }}</span>
                    </div>
                    <div class="right">
                      {{
                        $fanyi(
                          i.problem_goods_status
                            ? $fanyi("问题产品") +
                                " (" +
                                $fanyi(i.problem_goods_status) +
                                ")"
                            : i.status_name
                        )
                      }}
                    </div>
                  </div>
                  <!-- 内容区域 -->
                  <div class="shop-pic-text">
                    <!-- 左侧图片 @click="$fun.toCommodityDetails(item.goodsId, item.from_platform)"-->
                    <div
                      class="pic-box"
                      @click.stop="
                        $fun.toCommodityDetails(i.goods_id, i.from_platform)
                      "
                    >
                      <van-image
                        lazy-load
                        @click.stop="
                          $fun.toCommodityDetails(i.goods_id, i.from_platform)
                        "
                        :src="i.pic"
                      >
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                      </van-image>
                    </div>
                    <!-- 右侧 -->
                    <div class="right-box">
                      <div class="top-text">{{ i.goods_title }}</div>
                      <div class="ProductAttribute" @click="show(i)">
                        {{ processingintent(i.detail) }}
                        <van-icon name="arrow-down" />
                      </div>
                      <!-- 价格 -->
                      <div class="price">
                        <span
                          >{{
                            $fun.RMBNumSegmentation($fun.ceil(i.confirm_price))
                          }}{{ $fanyi("元") }}
                          <span class="riyaun"
                            >({{
                              $fun.JPYNumSegmentation(
                                $fun.roundNumber(
                                  i.confirm_price * $store.state.exchangeRate
                                )
                              )
                            }}円)</span
                          ></span
                        >
                        <span
                          >X <span class="num">{{ i.confirm_num }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- 国内运费 -->
                  <div class="freight">
                    <span class="left">{{ $fanyi("国内运费") }}：</span>
                    <p>
                      <span>{{
                        $fun.RMBNumSegmentation($fun.ceil(i.confirm_freight)) +
                        $fanyi("元")
                      }}</span>
                      <span
                        >(
                        {{
                          $fun.roundNumber(
                            i.confirm_freight * $store.state.exchangeRate
                          ) + $fanyi("円")
                        }})</span
                      >
                    </p>
                  </div>
                  <!-- 小计 -->
                  <div class="total">
                    <span class="left">{{ $fanyi("小计") }}：</span>
                    <p>
                      <span>{{
                        $fun.RMBNumSegmentation(
                          $fun.ceil(
                            i.confirm_price * i.confirm_num +
                              Number(i.confirm_freight)
                          )
                        ) + $fanyi("元")
                      }}</span>
                      <span
                        >(
                        {{
                          $fun.RMBNumSegmentation(
                            $fun.roundNumber(
                              (i.confirm_price * i.confirm_num +
                                Number(i.confirm_freight)) *
                                $store.state.exchangeRate
                            )
                          ) + $fanyi("円")
                        }})</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <noData v-if="!loading && !wuliulist.alllist.length" />
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab :title="$fanyi('等待购买')">
        <van-pull-refresh
          v-model="wuliulist.Waittobuyrefreshing"
          @refresh="onRefresh"
          head-height="100"
          :loosing-text="$fanyi('释放即可刷新...')"
          :pulling-text="$fanyi('下拉刷新')"
          :loading-text="$fanyi('加载中')"
          :success-text="$fanyi('加载成功')"
        >
          <van-list
            v-model:loading="loading"
            :loading-text="$fanyi('加载中')"
            :finished="wuliulist.Waittobuyfinished"
            :finished-text="$fanyi('')"
            @load="onLoad"
          >
            <div class="main">
              <div
                class="orderitem"
                v-for="(item, ind) in wuliulist.Waittobuylist"
                :key="ind"
              >
                <div
                  class="ordersn"
                  v-longpress="
                    () => {
                      copySn(item.order_sn);
                    }
                  "
                >
                  {{ $fanyi("订单号") }} : <span> {{ item.order_sn }}</span>
                </div>
                <div
                  class="item"
                  v-for="(i, index) in item.order_detail"
                  :key="index"
                >
                  <!-- !标题 -->
                  <div class="top-title">
                    <div class="left">
                      <img
                        src="../../../../assets/user-img/矢量智能对象.png"
                        alt=""
                      />
                      <span class="fanhao">{{ $fanyi("番号") }} :</span>
                      <span>#{{ i.sorting }}</span>
                    </div>
                    <div class="right">
                      {{
                        $fanyi(
                          i.problem_goods_status
                            ? $fanyi("问题产品") +
                                " (" +
                                $fanyi(i.problem_goods_status) +
                                ")"
                            : i.status_name
                        )
                      }}
                    </div>
                  </div>
                  <!-- 内容区域 -->
                  <div class="shop-pic-text">
                    <!-- 左侧图片 -->
                    <div
                      class="pic-box"
                      @click.stop="
                        $fun.toCommodityDetails(i.goods_id, i.from_platform)
                      "
                    >
                      <van-image
                        lazy-load
                        @click.stop="
                          $fun.toCommodityDetails(i.goods_id, i.from_platform)
                        "
                        :src="i.pic"
                      >
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                      </van-image>
                    </div>
                    <!-- 右侧 -->
                    <div class="right-box">
                      <div class="top-text">{{ i.goods_title }}</div>
                      <div class="ProductAttribute" @click="show(i)">
                        {{ processingintent(i.detail) }}
                        <van-icon name="arrow-down" />
                      </div>
                      <!-- 价格 -->
                      <div class="price">
                        <span
                          >{{
                            $fun.RMBNumSegmentation($fun.ceil(i.confirm_price))
                          }}{{ $fanyi("元") }}
                          <span class="riyaun"
                            >({{
                              $fun.JPYNumSegmentation(
                                $fun.roundNumber(
                                  i.confirm_price * $store.state.exchangeRate
                                )
                              )
                            }}円)</span
                          ></span
                        >
                        <span
                          >X <span class="num">{{ i.confirm_num }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- 国内运费 -->
                  <div class="freight">
                    <span class="left">{{ $fanyi("国内运费") }}：</span>
                    <p>
                      <span>{{
                        $fun.RMBNumSegmentation($fun.ceil(i.confirm_freight)) +
                        $fanyi("元")
                      }}</span>
                      <span
                        >(
                        {{
                          $fun.roundNumber(
                            i.confirm_freight * $store.state.exchangeRate
                          ) + $fanyi("円")
                        }})</span
                      >
                    </p>
                  </div>
                  <!-- 小计 -->

                  <div class="total">
                    <span class="left">{{ $fanyi("小计") }}：</span>

                    <p>
                      <span>{{
                        $fun.RMBNumSegmentation(
                          $fun.ceil(
                            i.confirm_price * i.confirm_num +
                              Number(i.confirm_freight)
                          )
                        ) + $fanyi("元")
                      }}</span>
                      <span
                        >(
                        {{
                          $fun.RMBNumSegmentation(
                            $fun.roundNumber(
                              (i.confirm_price * i.confirm_num +
                                Number(i.confirm_freight)) *
                                $store.state.exchangeRate
                            )
                          ) + $fanyi("円")
                        }})</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <noData v-if="!loading && !wuliulist.Waittobuylist.length" />
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab :title="$fanyi('待发货')">
        <van-pull-refresh
          v-model="wuliulist.toBeShippedrefreshing"
          @refresh="onRefresh"
          head-height="100"
          :loosing-text="$fanyi('释放即可刷新...')"
          :pulling-text="$fanyi('下拉刷新')"
          :loading-text="$fanyi('加载中')"
          :success-text="$fanyi('加载成功')"
        >
          <van-list
            v-model:loading="loading"
            :loading-text="$fanyi('加载中')"
            :finished="wuliulist.toBeShippedfinished"
            :finished-text="$fanyi('')"
            @load="onLoad"
          >
            <div class="main">
              <div
                class="orderitem"
                v-for="(item, ind) in wuliulist.toBeShippedlist"
                :key="ind"
              >
                <div
                  class="ordersn"
                  v-longpress="
                    () => {
                      copySn(item.order_sn);
                    }
                  "
                >
                  {{ $fanyi("订单号") }} : <span> {{ item.order_sn }}</span>
                </div>
                <div
                  class="item"
                  v-for="(i, index) in item.order_detail"
                  :key="index"
                >
                  <!-- !标题 -->
                  <div class="top-title">
                    <div class="left">
                      <img
                        src="../../../../assets/user-img/矢量智能对象.png"
                        alt=""
                      />
                      <span class="fanhao">{{ $fanyi("番号") }} :</span>
                      <span>#{{ i.sorting }}</span>
                    </div>
                    <div class="right">
                      {{
                        $fanyi(
                          i.problem_goods_status
                            ? $fanyi("问题产品") +
                                " (" +
                                $fanyi(i.problem_goods_status) +
                                ")"
                            : i.status_name
                        )
                      }}
                    </div>
                  </div>
                  <!-- 内容区域 -->
                  <div class="shop-pic-text">
                    <!-- 左侧图片 @click="$fun.toCommodityDetails(item.goodsId, item.from_platform)"-->
                    <div
                      class="pic-box"
                      @click.stop="
                        $fun.toCommodityDetails(i.goods_id, i.from_platform)
                      "
                    >
                      <van-image
                        lazy-load
                        @click.stop="
                          $fun.toCommodityDetails(i.goods_id, i.from_platform)
                        "
                        :src="i.pic"
                      >
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                      </van-image>
                    </div>
                    <!-- 右侧 -->
                    <div class="right-box">
                      <div class="top-text">{{ i.goods_title }}</div>
                      <div class="ProductAttribute" @click="show(i)">
                        {{ processingintent(i.detail) }}
                        <van-icon name="arrow-down" />
                      </div>
                      <!-- 价格 -->
                      <div class="price">
                        <span
                          >{{
                            $fun.RMBNumSegmentation($fun.ceil(i.confirm_price))
                          }}{{ $fanyi("元") }}
                          <span class="riyaun"
                            >({{
                              $fun.JPYNumSegmentation(
                                $fun.roundNumber(
                                  i.confirm_price * $store.state.exchangeRate
                                )
                              )
                            }}円)</span
                          ></span
                        >
                        <span
                          >X <span class="num">{{ i.confirm_num }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- 国内运费 -->
                  <div class="freight">
                    <span class="left">{{ $fanyi("国内运费") }}：</span>
                    <p>
                      <span>{{
                        $fun.RMBNumSegmentation($fun.ceil(i.confirm_freight)) +
                        $fanyi("元")
                      }}</span>
                      <span
                        >(
                        {{
                          $fun.roundNumber(
                            i.confirm_freight * $store.state.exchangeRate
                          ) + $fanyi("円")
                        }})</span
                      >
                    </p>
                  </div>
                  <!-- 小计 -->
                  <div class="total">
                    <span class="left">{{ $fanyi("小计") }}：</span>
                    <p>
                      <span>{{
                        $fun.RMBNumSegmentation(
                          $fun.ceil(
                            i.confirm_price * i.confirm_num +
                              Number(i.confirm_freight)
                          )
                        ) + $fanyi("元")
                      }}</span>
                      <span
                        >(
                        {{
                          $fun.RMBNumSegmentation(
                            $fun.roundNumber(
                              (i.confirm_price * i.confirm_num +
                                Number(i.confirm_freight)) *
                                $store.state.exchangeRate
                            )
                          ) + $fanyi("円")
                        }})</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <noData v-if="!loading && !wuliulist.toBeShippedlist.length" />
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab :title="$fanyi('国内派送中')">
        <div class="main">
          <van-pull-refresh
            v-model="wuliulist.Domesticdeliveryrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="wuliulist.Domesticdeliveryfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div class="main">
                <div
                  class="orderitem"
                  v-for="(item, ind) in wuliulist.Domesticdeliverylist"
                  :key="ind"
                >
                  <div
                    class="ordersn"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }} : <span> {{ item.order_sn }}</span>
                  </div>
                  <div
                    class="item"
                    v-for="(i, index) in item.order_detail"
                    :key="index"
                  >
                    <!-- !标题 -->
                    <div class="top-title">
                      <div class="left">
                        <img
                          src="../../../../assets/user-img/矢量智能对象.png"
                          alt=""
                        />
                        <span class="fanhao">{{ $fanyi("番号") }} :</span>
                        <span>#{{ i.sorting }}</span>
                      </div>
                      <div class="right">
                        {{
                          $fanyi(
                            i.problem_goods_status
                              ? $fanyi("问题产品") +
                                  " (" +
                                  $fanyi(i.problem_goods_status) +
                                  ")"
                              : i.status_name
                          )
                        }}
                      </div>
                    </div>
                    <!-- 内容区域 -->
                    <div class="shop-pic-text">
                      <!-- 左侧图片 -->
                      <div
                        class="pic-box"
                        @click.stop="
                          $fun.toCommodityDetails(i.goods_id, i.from_platform)
                        "
                      >
                        <van-image
                          lazy-load
                          @click.stop="
                            $fun.toCommodityDetails(i.goods_id, i.from_platform)
                          "
                          :src="i.pic"
                        >
                          <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                          </template>
                        </van-image>
                      </div>
                      <!-- 右侧 -->
                      <div class="right-box">
                        <div class="top-text">{{ i.goods_title }}</div>
                        <div class="ProductAttribute" @click="show(i)">
                          {{ processingintent(i.detail) }}
                          <van-icon name="arrow-down" />
                        </div>
                        <!-- 价格 -->
                        <div class="price">
                          <span
                            >{{
                              $fun.RMBNumSegmentation(
                                $fun.ceil(i.confirm_price)
                              )
                            }}{{ $fanyi("元") }}
                            <span class="riyaun"
                              >({{
                                $fun.JPYNumSegmentation(
                                  $fun.roundNumber(
                                    i.confirm_price * $store.state.exchangeRate
                                  )
                                )
                              }}円)</span
                            ></span
                          >
                          <span
                            >X <span class="num">{{ i.confirm_num }}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- 国内运费 -->
                    <div class="freight">
                      <span class="left">{{ $fanyi("国内运费") }}：</span>
                      <p>
                        <span>{{
                          $fun.RMBNumSegmentation(
                            $fun.ceil(i.confirm_freight)
                          ) + $fanyi("元")
                        }}</span>
                        <span
                          >(
                          {{
                            $fun.roundNumber(
                              i.confirm_freight * $store.state.exchangeRate
                            ) + $fanyi("円")
                          }})</span
                        >
                      </p>
                    </div>
                    <!-- 小计 -->
                    <div class="total">
                      <span class="left">{{ $fanyi("小计") }}：</span>

                      <p>
                        <span>{{
                          $fun.RMBNumSegmentation($fun.ceil(i.total)) +
                          $fanyi("元")
                        }}</span>
                        <span
                          >(
                          {{
                            $fun.RMBNumSegmentation(
                              $fun.roundNumber(
                                i.total * $store.state.exchangeRate
                              )
                            ) + $fanyi("円")
                          }})</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <noData
                v-if="!loading && !wuliulist.Domesticdeliverylist.length"
              />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <van-tab :title="$fanyi('已签收')">
        <div class="main">
          <van-pull-refresh
            v-model="wuliulist.receiptrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="wuliulist.receiptfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div class="main">
                <div
                  class="orderitem"
                  v-for="(item, ind) in wuliulist.receiptlist"
                  :key="ind"
                >
                  <div
                    class="ordersn"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }} : <span> {{ item.order_sn }}</span>
                  </div>
                  <div
                    class="item"
                    v-for="(i, index) in item.order_detail"
                    :key="index"
                  >
                    <!-- !标题 -->
                    <div class="top-title">
                      <div class="left">
                        <img
                          src="../../../../assets/user-img/矢量智能对象.png"
                          alt=""
                        />
                        <span class="fanhao">{{ $fanyi("番号") }} :</span>
                        <span>#{{ i.sorting }}</span>
                      </div>
                      <div class="right">
                        {{
                          $fanyi(
                            i.problem_goods_status
                              ? $fanyi("问题产品") +
                                  " (" +
                                  $fanyi(i.problem_goods_status) +
                                  ")"
                              : i.status_name
                          )
                        }}
                      </div>
                    </div>
                    <!-- 内容区域 -->
                    <div class="shop-pic-text">
                      <!-- 左侧图片 -->
                      <div
                        class="pic-box"
                        @click.stop="
                          $fun.toCommodityDetails(i.goods_id, i.from_platform)
                        "
                      >
                        <van-image
                          lazy-load
                          @click.stop="
                            $fun.toCommodityDetails(i.goods_id, i.from_platform)
                          "
                          :src="i.pic"
                        >
                          <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                          </template>
                        </van-image>
                      </div>
                      <!-- 右侧 -->
                      <div class="right-box">
                        <div class="top-text">{{ i.goods_title }}</div>
                        <div class="ProductAttribute" @click="show(i)">
                          {{ processingintent(i.detail) }}
                          <van-icon name="arrow-down" />
                        </div>
                        <!-- 价格 -->
                        <div class="price">
                          <span
                            >{{
                              $fun.RMBNumSegmentation(
                                $fun.ceil(i.confirm_price)
                              )
                            }}{{ $fanyi("元") }}
                            <span class="riyaun"
                              >({{
                                $fun.JPYNumSegmentation(
                                  $fun.roundNumber(
                                    i.confirm_price * $store.state.exchangeRate
                                  )
                                )
                              }}円)</span
                            ></span
                          >
                          <span
                            >X <span class="num">{{ i.confirm_num }}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- 国内运费 -->
                    <div class="freight">
                      <span class="left">{{ $fanyi("国内运费") }}：</span>
                      <p>
                        <span>{{
                          $fun.RMBNumSegmentation(
                            $fun.ceil(i.confirm_freight)
                          ) + $fanyi("元")
                        }}</span>
                        <span
                          >(
                          {{
                            $fun.roundNumber(
                              i.confirm_freight * $store.state.exchangeRate
                            ) + $fanyi("円")
                          }})</span
                        >
                      </p>
                    </div>
                    <!-- 小计 -->

                    <div class="total">
                      <span class="left">{{ $fanyi("小计") }}：</span>

                      <p>
                        <span>{{
                          $fun.RMBNumSegmentation(
                            $fun.ceil(
                              i.confirm_price * i.confirm_num +
                                Number(i.confirm_freight)
                            )
                          ) + $fanyi("元")
                        }}</span>
                        <span
                          >(
                          {{
                            $fun.RMBNumSegmentation(
                              $fun.roundNumber(
                                (i.confirm_price * i.confirm_num +
                                  Number(i.confirm_freight)) *
                                  $store.state.exchangeRate
                              )
                            ) + $fanyi("円")
                          }})</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <noData v-if="!loading && !wuliulist.receiptlist.length" />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <van-tab :title="$fanyi('商品作业中')">
        <div class="main">
          <van-pull-refresh
            v-model="wuliulist.Intheshelfrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="wuliulist.Intheshelffinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div class="main">
                <div
                  class="orderitem"
                  v-for="(item, ind) in wuliulist.Intheshelflist"
                  :key="ind"
                >
                  <div
                    class="ordersn"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }} : <span> {{ item.order_sn }}</span>
                  </div>
                  <div
                    class="item"
                    v-for="(i, index) in item.order_detail"
                    :key="index"
                  >
                    <!-- !标题 -->
                    <div class="top-title">
                      <div class="left">
                        <img
                          src="../../../../assets/user-img/矢量智能对象.png"
                          alt=""
                        />
                        <span class="fanhao">{{ $fanyi("番号") }} :</span>
                        <span>#{{ i.sorting }}</span>
                      </div>
                      <div class="right">
                        {{
                          $fanyi(
                            i.problem_goods_status
                              ? $fanyi("问题产品") +
                                  " (" +
                                  $fanyi(i.problem_goods_status) +
                                  ")"
                              : i.status_name
                          )
                        }}
                      </div>
                    </div>
                    <!-- 内容区域 -->
                    <div class="shop-pic-text">
                      <!-- 左侧图片 -->
                      <div
                        class="pic-box"
                        @click.stop="
                          $fun.toCommodityDetails(i.goods_id, i.from_platform)
                        "
                      >
                        <van-image
                          lazy-load
                          @click.stop="
                            $fun.toCommodityDetails(i.goods_id, i.from_platform)
                          "
                          :src="i.pic"
                        >
                          <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                          </template>
                        </van-image>
                      </div>
                      <!-- 右侧 -->
                      <div class="right-box">
                        <div class="top-text">{{ i.goods_title }}</div>
                        <div class="ProductAttribute" @click="show(i)">
                          {{ processingintent(i.detail) }}
                          <van-icon name="arrow-down" />
                        </div>
                        <!-- 价格 -->
                        <div class="price">
                          <span
                            >{{
                              $fun.RMBNumSegmentation(
                                $fun.ceil(i.confirm_price)
                              )
                            }}{{ $fanyi("元") }}
                            <span class="riyaun"
                              >({{
                                $fun.JPYNumSegmentation(
                                  $fun.roundNumber(
                                    i.confirm_price * $store.state.exchangeRate
                                  )
                                )
                              }}円)</span
                            ></span
                          >
                          <span
                            >X <span class="num">{{ i.confirm_num }}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- 国内运费 -->
                    <div class="freight">
                      <span class="left">{{ $fanyi("国内运费") }}：</span>
                      <p>
                        <span>{{
                          $fun.RMBNumSegmentation(
                            $fun.ceil(i.confirm_freight)
                          ) + $fanyi("元")
                        }}</span>
                        <span
                          >(
                          {{
                            $fun.roundNumber(
                              i.confirm_freight * $store.state.exchangeRate
                            ) + $fanyi("円")
                          }})</span
                        >
                      </p>
                    </div>
                    <!-- 小计 -->

                    <div class="total">
                      <span class="left">{{ $fanyi("小计") }}：</span>

                      <p>
                        <span>{{
                          $fun.RMBNumSegmentation(
                            $fun.ceil(
                              i.confirm_price * i.confirm_num +
                                Number(i.confirm_freight)
                            )
                          ) + $fanyi("元")
                        }}</span>
                        <span
                          >(
                          {{
                            $fun.RMBNumSegmentation(
                              $fun.roundNumber(
                                (i.confirm_price * i.confirm_num +
                                  Number(i.confirm_freight)) *
                                  $store.state.exchangeRate
                              )
                            ) + $fanyi("円")
                          }})</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <noData v-if="!loading && !wuliulist.Intheshelflist.length" />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
    </van-tabs>
    <showAttribute ref="showAtt" />
  </div>
  <!-- 内容区域 -->
</template>
<script setup>
import noData from "./components/nodata.vue";
import inputBoxVue from "@/components/inputBox/index.vue";
import dayjs from "dayjs";
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
import { Toast } from "vant";
import { useRoute } from "vue-router";
// 商品规格组件
import showAttribute from "../../../../components/detailsAllShow/index.vue";
const route = useRoute();

const { proxy } = getCurrentInstance();
const searchkeyword = ref("");
const active = ref(0); // 全部0 等待购买 1 待发货2 国内派送中 3 已签收 4 上架中 5 上架完成 6
const state = ref("全部"); // 状态
const pageSize = ref(20); // 每页条数
// 判断路由是哪个
if (route.query.type == "all") {
  active.value = 0;
}
if (route.query.type == "Waittobuy") {
  active.value = 1;
}
if (route.query.type == "toBeShipped") {
  active.value = 2;
}
if (route.query.type == "Domesticdelivery") {
  active.value = 3;
}
if (route.query.type == "receipt") {
  active.value = 4;
}
if (route.query.type == "Intheshelf") {
  active.value = 5;
}
// if (route.query.type == "Oncompletion") {
//   active.value = 5;
// }
// 判断是那个页面来的
const loading = ref(false);
// 定义列表数据
const wuliulist = ref({
  alllist: [],
  allpage: 0,
  allfinished: false,
  allrefreshing: false,
  // 等待购买
  Waittobuylist: [],
  Waittobuypage: 0, //页码
  Waittobuyfinished: false, // 触底
  Waittobuyrefreshing: false, // 下拉
  // 待发货
  toBeShippedlist: [],
  toBeShippedpage: 0, //页码
  toBeShippedfinished: false, // 触底
  toBeShippedrefreshing: false, // 下拉
  // 国内派送中
  Domesticdeliverylist: [],
  Domesticdeliverypage: 0,
  Domesticdeliveryfinished: false,
  Domesticdeliveryrefreshing: false,
  // 已签收
  receiptlist: [],
  receiptpage: 0,
  receiptfinished: false,
  receiptrefreshing: false,
  // 上架中
  Intheshelflist: [],
  Intheshelfpage: 0,
  Intheshelffinished: false,
  Intheshelfrefreshing: false,
  // 上架完成
  Oncompletionlist: [],
  Oncompletionpage: 0,
  Oncompletionfinished: false,
  Oncompletionrefreshing: false,
});
// 加载动画
Toast.loading({
  duration: 0,
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
});
Toast.clear();

// // 获取数据
// const chinaLogisticsList = () => {
//   var tYear = new Date().getFullYear()
//   var startTime = dayjs(new Date(tYear, 0, 1)).format('YYYY-MM-DD') //当前年第一天
//   var endTime = dayjs(new Date(tYear, 11, 31)).format('YYYY-MM-DD') //当前年最后一天

//   console.log(startTime, endTime)

//   // proxy.$api.chinaLogisticsList({

//   // })
// }

const onLoad = async () => {
  // 首先进行判断
  let page = 0;
  // 全部0 等待购买 1 待发货2 国内派送中 3 已签收 4 上架中 5 上架完成 6
  if (active.value == 0) {
    state.value = "全部";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (wuliulist.value.allfinished == true) {
      wuliulist.value.allrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (wuliulist.value.alllist.length == 0) {
      wuliulist.value.allpage = 1;
    } else {
      wuliulist.value.allpage += 1;
    }
    page = wuliulist.value.allpage;
  }
  if (active.value == 1) {
    state.value = "等待购买";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (wuliulist.value.Waittobuyfinished == true) {
      wuliulist.value.Waittobuyrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (wuliulist.value.Waittobuylist.length == 0) {
      wuliulist.value.Waittobuypage = 1;
    } else {
      wuliulist.value.Waittobuypage += 1;
    }
    page = wuliulist.value.Waittobuypage;
  }
  if (active.value == 2) {
    state.value = "待发货";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (wuliulist.value.toBeShippedfinished == true) {
      wuliulist.value.toBeShippedrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (wuliulist.value.toBeShippedlist.length == 0) {
      wuliulist.value.toBeShippedpage = 1;
    } else {
      wuliulist.value.toBeShippedpage += 1;
    }
    page = wuliulist.value.toBeShippedpage;
  }
  if (active.value == 3) {
    state.value = "国内派送中";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (wuliulist.value.Domesticdeliveryfinished == true) {
      wuliulist.value.Domesticdeliveryrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (wuliulist.value.Domesticdeliverylist.length == 0) {
      wuliulist.value.Domesticdeliverypage = 1;
    } else {
      wuliulist.value.Domesticdeliverypage += 1;
    }
    page = wuliulist.value.Domesticdeliverypage;
  }
  if (active.value == 4) {
    state.value = "已签收";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (wuliulist.value.receiptfinished == true) {
      wuliulist.value.receiptrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (wuliulist.value.receiptlist.length == 0) {
      wuliulist.value.receiptpage = 1;
    } else {
      wuliulist.value.receiptpage += 1;
    }
    page = wuliulist.value.receiptpage;
  }
  if (active.value == 5) {
    state.value = "上架中";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (wuliulist.value.Intheshelffinished == true) {
      wuliulist.value.Intheshelfrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (wuliulist.value.Intheshelflist.length == 0) {
      wuliulist.value.Intheshelfpage = 1;
    } else {
      wuliulist.value.Intheshelfpage += 1;
    }
    page = wuliulist.value.Intheshelfpage;
  }
  if (active.value == 6) {
    state.value = "上架完成";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (wuliulist.value.Oncompletionfinished == true) {
      wuliulist.value.Oncompletionrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (wuliulist.value.Oncompletionlist.length == 0) {
      wuliulist.value.Oncompletionpage = 1;
    } else {
      wuliulist.value.Oncompletionpage += 1;
    }
    page = wuliulist.value.Oncompletionpage;
  }

  // 获取国际物流列表
  const res = await proxy.$api.chinaLogisticsList({
    status: state.value,
    page: page,
    pageSize: pageSize.value,
  });
  // 如果请求错误
  if (res.code != 0) {
    if (active.value == 0) {
      wuliulist.value.allrefreshing = false;
      wuliulist.value.allfinished = true;
    }
    if (active.value == 1) {
      wuliulist.value.Waittobuyrefreshing = false;
      wuliulist.value.Waittobuyfinished = true;
    }
    if (active.value == 2) {
      wuliulist.value.toBeShippedrefreshing = false;
      wuliulist.value.toBeShippedfinished = true;
    }
    if (active.value == 3) {
      wuliulist.value.Domesticdeliveryrefreshing = false;
      wuliulist.value.Domesticdeliveryfinished = true;
    }
    if (active.value == 4) {
      wuliulist.value.receiptrefreshing = false;
      wuliulist.value.receiptfinished = true;
    }
    if (active.value == 5) {
      wuliulist.value.Intheshelfrefreshing = false;
      wuliulist.value.Intheshelffinished = true;
    }
    if (active.value == 6) {
      wuliulist.value.Oncompletionrefreshing = false;
      wuliulist.value.Oncompletionfinished = true;
    }
    Toast.clear();
    loading.value = false;
    Toast.fail(proxy.$fanyi(res.msg));
    return;
  }
  res.data.data.forEach((item) => {
    item.confirm_freight = 0;
    item.confirm_num = 0;
    item.confirm_price = 0;
    item.total = 0;
    item.showStatus = true;
    item.order_detail.forEach((items) => {
      items.optionPrice = 0;
      if (items.option != null) {
        if (items.option.length > 0) {
          items.option.forEach((optionItem) => {
            if (
              optionItem.checked === true &&
              optionItem.num >= 1 &&
              optionItem.price_type === 0
            ) {
              items.optionPrice += optionItem.num * Number(optionItem.price);
            }
            // if (optionItem.checked === true && optionItem.price_type === 1) {
            //   this.optionTotalPrice +=
            //     Number(items.confirm_price) *
            //     items.confirm_num *
            //     (Number(optionItem.price) / 100);
            // }
          });
        }
      }
      item.confirm_num += Number(items.confirm_num);
      item.confirm_price += Number(items.confirm_price);
      items.service_rate_value =
        items.confirm_price * items.confirm_num * Number(items.service_rate);
      item.total +=
        Number(items.confirm_num * items.confirm_price) +
        Number(items.confirm_freight) +
        items.service_rate_value +
        items.optionPrice;
      items.total =
        Number(items.confirm_num * items.confirm_price) +
        Number(items.confirm_freight) +
        items.service_rate_value +
        items.optionPrice;
    });
  });

  // 进行判断渲染数据
  if (active.value == 0) {
    // 判断是下刷新海是触底  临时保存
    if (wuliulist.value.allrefreshing == true) {
      wuliulist.value.alllist = [...res.data?.data, ...wuliulist.value.alllist];
    } else {
      wuliulist.value.alllist = [...wuliulist.value.alllist, ...res.data?.data];
    }

    loading.value = false;
    wuliulist.value.allrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      wuliulist.value.alllist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      wuliulist.value.allfinished = true;
    }
  }
  if (active.value == 1) {
    // 判断是下刷新海是触底
    if (wuliulist.value.Waittobuyrefreshing == true) {
      wuliulist.value.Waittobuylist = [
        ...res.data?.data,
        ...wuliulist.value.Waittobuylist,
      ];
    } else {
      wuliulist.value.Waittobuylist = [
        ...wuliulist.value.Waittobuylist,
        ...res.data?.data,
      ];
    }

    loading.value = false;
    wuliulist.value.Waittobuyrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      wuliulist.value.Waittobuylist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      wuliulist.value.Waittobuyfinished = true;
    }
  }
  if (active.value == 2) {
    // 判断是下刷新海是触底
    if (wuliulist.value.toBeShippedrefreshing == true) {
      wuliulist.value.toBeShippedlist = [
        ...res.data?.data,
        ...wuliulist.value.toBeShippedlist,
      ];
    } else {
      wuliulist.value.toBeShippedlist = [
        ...wuliulist.value.toBeShippedlist,
        ...res.data?.data,
      ];
    }

    loading.value = false;
    wuliulist.value.toBeShippedrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      wuliulist.value.toBeShippedlist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      wuliulist.value.toBeShippedfinished = true;
    }
  }
  if (active.value == 3) {
    // 判断是下刷新海是触底
    if (wuliulist.value.Domesticdeliveryrefreshing == true) {
      wuliulist.value.Domesticdeliverylist = [
        ...res.data?.data,
        ...wuliulist.value.Domesticdeliverylist,
      ];
    } else {
      wuliulist.value.Domesticdeliverylist = [
        ...wuliulist.value.Domesticdeliverylist,
        ...res.data?.data,
      ];
    }

    loading.value = false;
    wuliulist.value.Domesticdeliveryrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      wuliulist.value.Domesticdeliverylist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      wuliulist.value.Domesticdeliveryfinished = true;
    }
  }
  if (active.value == 4) {
    // 判断是下刷新海是触底
    if (wuliulist.value.receiptrefreshing == true) {
      wuliulist.value.receiptlist = [
        ...res.data?.data,
        ...wuliulist.value.receiptlist,
      ];
    } else {
      wuliulist.value.receiptlist = [
        ...wuliulist.value.receiptlist,
        ...res.data?.data,
      ];
    }

    loading.value = false;
    wuliulist.value.receiptrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      wuliulist.value.receiptlist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      wuliulist.value.receiptfinished = true;
    }
  }
  if (active.value == 5) {
    // 判断是下刷新海是触底
    if (wuliulist.value.Intheshelfrefreshing == true) {
      wuliulist.value.Intheshelflist = [
        ...res.data?.data,
        ...wuliulist.value.Intheshelflist,
      ];
    } else {
      wuliulist.value.Intheshelflist = [
        ...wuliulist.value.Intheshelflist,
        ...res.data?.data,
      ];
    }

    loading.value = false;
    wuliulist.value.Intheshelfrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      wuliulist.value.Intheshelflist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      wuliulist.value.Intheshelffinished = true;
    }
  }
  if (active.value == 6) {
    // 判断是下刷新海是触底
    if (wuliulist.value.Oncompletionrefreshing == true) {
      wuliulist.value.Oncompletionlist = [
        ...res.data?.data,
        ...wuliulist.value.Oncompletionlist,
      ];
    } else {
      wuliulist.value.Oncompletionlist = [
        ...wuliulist.value.Oncompletionlist,
        ...res.data?.data,
      ];
    }

    loading.value = false;
    wuliulist.value.Oncompletionfinished = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      wuliulist.value.Oncompletionlist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      wuliulist.value.Oncompletionfinished = true;
    }
  }
};
// 点击tab触发的事件
const clicktabs = (val) => {
  active.value = val.name;

  //  进行判断重新加载数据
  if (orderstate.value == "全部") {
    active.value = 0;
    wuliulist.value.allpage = 0;
    wuliulist.value.allfinished = false;
    orderstate.value = "";
    wuliulist.value.alllist = [];
  }
};

// 下拉触发
const onRefresh = () => {
  onLoad();
};

// 输入框发生变化开始模糊搜索
// 搜索的订单状态
const orderstate = ref("");
// 搜索订单
const search = async () => {
  //  点击后开始发请求搜索
  if (searchkeyword.value !== "") {
    Toast.loading({
      duration: 0,
      message: proxy.$fanyi("加载中..."),
      forbidClick: true,
    });
    const res = await proxy.$api.chinaLogisticsList({
      status: "全部",
      order_sn: searchkeyword.value,
    });
    console.log(res);
    if (res.code !== 0) {
      Toast.fail(proxy.$fanyi(res.msg));
      Toast.clear();
      return;
    }

    if (res.data.data.length == 0) {
      Toast(proxy.$fanyi("查询不到您输入的订单号"));
      Toast.clear();
      return;
    }
    orderstate.value = "全部";
    // 搜索的去全部
    // console.log(state);
    Toast.clear();

    wuliulist.value.alllist = res.data.data;
    active.value = 0;
    wuliulist.value.allpage = 0;
    wuliulist.value.allfinished = true;
  } else {
    Toast(proxy.$fanyi("请输入配送单号"));
  }
};
// 处理商品属性
const processingintent = (arr) => {
  let str = "";
  arr.forEach((item) => {
    str += item.value + ";";
  });
  return str;
};
// 弹出属性框
const showAtt = ref("");
const show = (i) => {
  showAtt.value.open(i);
};

// 复制单号
const copySn = (order_sn) => {
  navigator.clipboard.writeText(order_sn);
  proxy.$message.primary(proxy.$fanyi("复制成功"));
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.headBox {
  span {
    font-size: 32px;
    font-family: PingFang SC !important;
    font-weight: 600;
    color: #000000;
  }
}

:deep().van-overlay {
  opacity: 0.4;
}

:deep() .van-icon-cross {
  width: 19px;
  height: 18px;
  top: 20px;
  right: 28px;
  font-size: 28px;
}

.search {
  width: 570px;
}

.content {
  width: 100%;

  min-height: 1380px;
  background-color: #f6f6f6;

  :deep() .van-tab__text {
    font-size: 28px;
    text-align: center;
    min-width: 100px !important;

    font-weight: 500;
  }

  :deep() .van-tabs__line {
    background: #b4272b;
  }

  :deep().van-tabs__wrap {
    height: 68px;
    position: sticky;
    top: 126px;
    z-index: 99;
  }

  .main {
    .orderitem {
      margin: auto;
      margin-top: 20px;
      width: 690px;
      min-height: 480px;
      background: #ffffff;
      border-radius: 6px;
      padding: 30px;
      margin-bottom: 20px;

      .ordersn,
      span {
        font-size: 28px;

        font-weight: 500;
        color: #000000;
        margin-bottom: 7px;
      }

      .item {
        width: 100%;
        height: 400px;
        border-bottom: 1px dashed #dfdfdf;
        padding-top: 30px;

        .top-title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            display: flex;
            align-items: center;

            img {
              width: 26px;
              height: 26px;
            }

            .fanhao {
              margin-left: 10px;
              margin-right: 15px;
            }

            span {
              font-size: 28px;

              font-weight: 400;
              color: #000000;
            }
          }

          .right {
            font-size: 24px;

            font-weight: 400;
            color: #b4272b;
          }
        }

        .shop-pic-text {
          display: flex;
          padding: 20px 0;

          .pic-box {
            margin-right: 21px;
            width: 180px;
            height: 180px;

            :deep() .van-image {
              width: 180px;
              height: 180px;
              background: #ffffff;
              border-radius: 6px;
            }
          }

          .right-box {
            .top-text {
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden; //溢出内容隐藏
              text-overflow: ellipsis; //文本溢出部分用省略号表示
              display: -webkit-box; //特别显示模式
              -webkit-line-clamp: 2; //行数
              line-clamp: 2;
              width: 100%;
              width: 430px;
              height: 76px;
              font-size: 28px;

              font-weight: 400;
              color: #000000;
              line-height: 42px;
              -webkit-box-orient: vertical; //盒子中内容竖直排列
              margin-bottom: 12px;
            }

            .ProductAttribute {
              white-space: nowrap; // 强制一行显示
              overflow: hidden; // 超出隐藏
              text-overflow: ellipsis; // 省略号
              padding-right: 40px;
              width: 350px;
              height: 42px;
              font-size: 22px;

              font-weight: 400;
              color: #999999;
              background: #fafafa;
              border: 1px solid #dfdfdf;
              border-radius: 6px;
              line-height: 42px;
              padding-left: 10px;
              position: relative;
              margin-bottom: 20px;

              :deep() .van-icon-arrow-down {
                position: absolute;
                right: 20px;
                color: #333333;
                top: 10px;
              }
            }

            .price {
              width: 100%;
              display: flex;
              justify-content: space-between;

              span {
                &:first-child {
                  font-size: 24px;

                  font-weight: 600;
                  color: #000000;

                  .riyaun {
                    font-size: 20px;
                    color: #000000;
                  }
                }

                &:last-child {
                  font-size: 20px;

                  font-weight: 400;
                  color: #999999;

                  .num {
                    margin-left: 5px;
                  }
                }
              }
            }
          }
        }

        .freight,
        .total {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left {
            display: flex;
            font-size: 24px;

            font-weight: 400;
            color: #000000;
          }
        }

        .freight {
          margin-bottom: 16px;

          p {
            display: flex;

            span {
              &:first-child {
                display: flex;
                font-size: 24px;

                font-weight: 600;
                color: #000000;
              }

              &:last-child {
                display: flex;
                font-size: 20px;
                color: #000000;
              }
            }
          }
        }

        .total {
          p {
            display: flex;

            span {
              display: flex;

              &:first-child {
                font-size: 24px;

                font-weight: 600;
                color: #b4272b;
              }

              &:last-child {
                font-size: 20px;
                color: #b4272b;
              }
            }
          }
        }
      }
    }
  }
}
</style>
