<template>
  <div class="noData">
    <div>
      <img :src="require('@/assets/icon/wwl.png')" alt="" />
      <span>{{ $fanyi("暂无历史记录") }}</span>
    </div>
  </div>
</template>
<script>
import { getCurrentInstance, ref } from "vue";
export default {
  setup(props) {
    const { proxy } = getCurrentInstance();
    return {};
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.noData {
  padding-top: 177px;
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 294px;
      height: 270px;
      margin-bottom: 35px;
    }
    span {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 40px;
      color: rgba(153, 153, 153, 1);
    }
    button {
      width: 240px;
      height: 90px;
      background: #b4272b;
      border-radius: 6px;
      font-size: 28px;
      color: #ffffff;
    }
  }
}
</style>
